import Loader from "components/Loader";
import React, { useState } from "react";
import {
  Button,
  Card,
  Table,
  Container,
  Row,
  Col,
  Modal,
  Form,
} from "react-bootstrap";
import { getCookie } from "utils/cookie";
import useAxios from "utils/useAxios";
import html2canvas from "html2canvas"; // Import html2canvas
import { FaDownload } from "react-icons/fa"; // Import FontAwesome icon for download
import "./Orders.css"; // Import custom CSS for additional styling
import { baseURL } from "utils/useAxios";
import axios from "axios";

function Orders() {
  const token = getCookie("token");
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [show, setShow] = useState(false);
  const [status, setStatus] = useState("");

  const { response, loading, error, fetchData } = useAxios({
    method: "Get",
    url: "/api/v1/orders",
    headers: {
      accept: "*/*",
      token: token,
    },
  });

  const handleShow = (order) => {
    setSelectedOrder(order);
    setStatus(order.status);
    setShow(true);
  };

  const handleClose = () => setShow(false);

  

const handleCompleteOrder = async (orderId) => {
  try {
    const response = await axios.put(`${baseURL}/api/v1/orders/${orderId}`, {},{
      headers: {
        token: token,
      },
    });

    console.log(response.data); // Logs the response data from the server
    location.href = "/admin/orders";
  } catch (err) {
    console.error("Failed to complete order:", err); // Logs any errors that occur
  }
};

  const downloadImage = async () => {
    const invoiceElement = document.getElementById("invoice-content");
    const canvas = await html2canvas(invoiceElement);
    const dataURL = canvas.toDataURL("image/png");
    const link = document.createElement("a");
    link.href = dataURL;
    link.download = `invoice_${selectedOrder._id}.png`;
    link.click();
  };

  if (loading) return <Loader />;
  if (error) return <h1>Connect To Technical Team..</h1>;

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h4">Orders List</Card.Title>
                <p className="card-category">Update Order Status</p>
              </Card.Header>

              <Card.Body className="table-full-width table-responsive px-0">
                <Table className="table-hover table-striped">
                  <thead>
                    <tr>
                      <th className="border-0">ID</th>
                      <th className="border-0">User</th>
                      <th className="border-0">Subtotal</th>
                      <th className="border-0">Delivery</th>
                      <th className="border-0">Total</th>
                      <th className="border-0">Created At</th>
                      <th className="border-0">Status</th>
                      <th className="border-0">Action</th>
                      <th className="border-0">Complete</th> {/* New column */}
                    </tr>
                  </thead>
                  <tbody>
                    {response.orders.map((order, idx) => (
                      <tr key={idx}>
                        <td>{order._id}</td>
                        <td>{order.user.name}</td>
                        <td>₹{order.subtotal}</td>
                        <td>₹{order.delivery}</td>
                        <td>₹{order.total}</td>
                        <td>{new Date(order.createdAt).toLocaleString()}</td>
                        <td>{order.status}</td>
                        <td>
                          <Button
                            variant="info"
                            onClick={() => handleShow(order)}
                          >
                            View Details
                          </Button>
                        </td>
                        <td>
                          {order.status !== "Delivered" && (
                            <Button
                              variant="success"
                              onClick={() => handleCompleteOrder(order._id)}
                            >
                              Complete Order
                            </Button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* Modal for Order Details */}
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header>
          <Modal.Title>Order Details</Modal.Title>

          <Button
            variant="secondary"
            onClick={downloadImage}
            className="ml-auto download-button"
            title="Download Invoice as Image"
          >
            <FaDownload />
          </Button>
        </Modal.Header>
        <Modal.Body>
          {selectedOrder && (
            <div id="invoice-content" className="invoice">
              <header className="invoice-header">
                <h1 className="invoice-title">Invoice</h1>
                <p className="invoice-date">
                  Date: {new Date(selectedOrder.createdAt).toLocaleDateString()}
                </p>
              </header>

              <section className="invoice-user mt-8">
                <Row>
                  <Col md={6}>
                    <p>
                      <strong>Name:</strong> {selectedOrder.user?.name || "N/A"}
                    </p>
                  </Col>
                  <Col md={6}>
                    <p>
                      <strong>Email:</strong>{" "}
                      {selectedOrder.user?.email || "N/A"}
                    </p>
                  </Col>
                </Row>
              </section>

              <section className="invoice-address">
                <h5>Order Address</h5>
                <div className="address-box">
                  <Row>
                    <Col md={4}>
                      <p>
                        <strong>First Name:</strong>{" "}
                        {selectedOrder.orderAddress?.firstName || "N/A"}
                      </p>
                    </Col>
                    <Col md={4}>
                      <p>
                        <strong>Last Name:</strong>{" "}
                        {selectedOrder.orderAddress?.lastName || "N/A"}
                      </p>
                    </Col>
                    <Col md={4}>
                      <p>
                        <strong>Street Address:</strong>{" "}
                        {selectedOrder.orderAddress?.streetAddress || "N/A"}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      <p>
                        <strong>City:</strong>{" "}
                        {selectedOrder.orderAddress?.city || "N/A"}
                      </p>
                    </Col>
                    <Col md={4}>
                      <p>
                        <strong>Country:</strong>{" "}
                        {selectedOrder.orderAddress?.country || "N/A"}
                      </p>
                    </Col>
                    <Col md={4}>
                      <p>
                        <strong>ZIP Code:</strong>{" "}
                        {selectedOrder.orderAddress?.zip || "N/A"}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      <p>
                        <strong>Phone:</strong>{" "}
                        {selectedOrder.orderAddress?.phone || "N/A"}
                      </p>
                    </Col>
                  </Row>
                </div>
              </section>

              <section className="invoice-items">
                <h5>Order Items</h5>
                <Table bordered>
                  <thead>
                    <tr>
                      <th>Product ID</th>
                      <th>Product Title</th>
                      <th>Price</th>
                      <th>Quantity</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedOrder.orderItems.map((item, idx) => (
                      <tr key={idx}>
                        <td>{item._id}</td>
                        <td>{item.productId.title || "N/A"}</td>
                        <td>₹{item.price || 0}</td>
                        <td>{item.quantity || 0}</td>
                        <td>₹{item.total || 0}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </section>

              <section className="invoice-summary">
                <h5>Summary</h5>
                <div className="summary-box">
                  <p>
                    <strong>Subtotal:</strong> ₹{selectedOrder.subtotal}
                  </p>
                  <p>
                    <strong>Delivery:</strong> ₹{selectedOrder.delivery}
                  </p>
                  <p>
                    <strong>Total:</strong> ₹{selectedOrder.total}
                  </p>
                </div>
              </section>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
         
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Orders;
